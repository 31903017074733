import { fmt, sepFormat } from 'utilities';
import { createColumn } from './createColumn';
import { right } from './style.css';

export const GrossTotalColumn = createColumn(
  {
    key: 'gross_total',
    name: 'Gross total',
    className: right,
    width: 96,
  },
  { renderView: ({ row }) => (row.kind === 'SMART' ? null : sepFormat(row.sums.gross_total)) },
  { renderView: (props) => fmt(props.row.grossTotal) },
  { renderSummaryCell: (props) => fmt(props.row.grossTotal) },
);
