import { DotBadge } from './DotBadge';
import { createColumn } from './createColumn';
import { ButtonGroupField, TransparencyIcon } from 'components';
import { fmt, parseBig, sepFormat } from 'utilities';
import { Box, Col, Row } from '@ff-it/ui';
import { ReactElement, ReactNode } from 'react';
import {
  DialogEditor,
  RenderCellProps,
  RenderEditCellProps,
  RenderSummaryCellProps,
  textEditor,
} from 'components/DataGrid';
import { GridGroup, GridRow, GridSummaryRow, RowOrGroup } from '../types';
import { transparencyOptions } from 'options';
import { CheckField, DecimalField } from '@ff-it/form';
import { usePlanController } from '../../usePlanController';
import { budgetEditor, clientTotalColumn } from './style.css';
import { Budget } from '../../Budget';

type ViewRowProps = {
  label: ReactNode;
  value: ReactNode;
};
function ViewRow({ label, value }: ViewRowProps): ReactElement {
  return (
    <Row display="flex" alignItems="center" lineHeight="xs">
      <Col display="flex" alignItems="center" width="2/5" fontSize="xs">
        {label}
      </Col>
      <Col textAlign="end">{value}</Col>
    </Row>
  );
}

function View({ row }: RenderCellProps<GridRow, any>): ReactNode {
  const {
    sums: { planned_income, target_income, planned_budget },
  } = row;

  if (row.kind === 'SMART') {
    if (row.direction === 'CLIENT' || row.direction === 'BOTH') {
      return (
        <>
          <ViewRow label="Planned" value={<div data-testid="planned">{sepFormat(planned_income)}</div>} />
          <ViewRow
            label={<div className="font-weight-bold">Actual</div>}
            value={
              <div className="font-weight-bold" data-testid="target">
                {sepFormat(target_income)}
              </div>
            }
          />
        </>
      );
    }
    return null;
  }

  const transparency = row.client_total.transparency;
  const renderTarget = target_income !== planned_income;

  // danger if overspent
  const className = `font-weight-bold${planned_budget?.is_overspent ? ' text-danger' : ''}`;

  return (
    <>
      {planned_budget && <DotBadge />}
      <ViewRow
        label={titles[transparency]}
        value={
          <>
            <div data-testid="planned" className={!renderTarget ? className : undefined}>
              {sepFormat(planned_income)}
            </div>
            {renderTarget && (
              <div data-testid="target" className={className}>
                {sepFormat(target_income)}
              </div>
            )}
          </>
        }
      />
    </>
  );
}

const iconOptions = transparencyOptions.map(({ value, label }) => ({
  value,
  label: (
    <>
      <TransparencyIcon transparency={value} className="mr-1" /> {label}
    </>
  ),
}));

function Edit(props: RenderEditCellProps<GridRow, any>): ReactNode {
  const controller = usePlanController();
  return (
    <DialogEditor
      {...props}
      submitHandler={async (values) =>
        await controller.formRequest({
          method: 'PUT',
          url: `plan/rows/${props.row.id}/client_total/`,
          body: values,
        })
      }
      dialogHeader="Client totals"
      testId="client-total-edit"
      initialValues={props.row.client_total}
    >
      <ButtonGroupField
        name="transparency"
        label="Invoice transparency"
        variant="outline-primary"
        options={iconOptions}
      />

      <Box>
        <DecimalField name="budget" label="Budget" className="mb-1" precision={2} />
        <div className="form-group">
          <CheckField name="budget_include_smart_rates" fieldLabel="including smart rates" />
        </div>
      </Box>
    </DialogEditor>
  );
}

const titles = {
  DELIVERED: 'Delivered',
  PLANNED: 'Planned',
} as const;

function ViewBudget(props: RenderCellProps<GridGroup, GridSummaryRow>): ReactNode {
  const budget = props.row.budget ? parseBig(props.row.budget) : null;
  return (
    <Row alignItems="center">
      {budget && (
        <Col width="content" paddingRight="sm">
          <Budget budget={budget} amount={props.row.clientTotal} />
        </Col>
      )}
      <Col textAlign="right" title={fmt(props.row.clientTotal)} data-testid="amount">
        {fmt(props.row.clientTotal)}
      </Col>
    </Row>
  );
}

function ViewSummaryBudget({ row: { clientTotal } }: RenderSummaryCellProps<GridSummaryRow, RowOrGroup>): ReactNode {
  return fmt(clientTotal);
}

export const ClientTotalColumn = createColumn(
  { key: 'client_total', name: 'Client total', width: 156, className: clientTotalColumn },
  {
    renderView: (props) => <View {...props} />,
    renderEdit: (props) => <Edit {...props} />,
    displayCellContent: true,
  },
  {
    renderView: (props) => <ViewBudget {...props} />,
    renderEdit: (props) => textEditor({ ...props, field: 'budget', className: budgetEditor }),
  },
  { renderSummaryCell: (props) => <ViewSummaryBudget {...props} /> },
);
