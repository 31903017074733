import { ReactNode } from 'react';
import { DialogEditor, type RenderEditCellProps } from 'components/DataGrid';
import type { GridRow, GridSummaryRow } from '../../types';
import type { RowFactors } from 'modules/campaign/row';
import { DecimalField } from '@ff-it/form';
import { FactorSelector } from './FactorSelector';
import { useDepartmentFactors, usePlanFactors } from '../../hooks';
import invariant from 'tiny-invariant';

import { PlanFactor } from 'modules/campaign/block/types';
import { DepartmentFactor } from 'modules/supplier/factors';
import { usePlanController } from '../../../usePlanController';

function useRowFactors(row: GridRow): {
  planFactors: PlanFactor[];
  departmentFactors: DepartmentFactor[];
} {
  invariant(row.supplier);
  const allPlanFactors = usePlanFactors(); // @TODO filterd
  const departmentIds = new Set([row.supplier?.product.department.id || 0]);
  // product might get assigned different department
  const factorIds = new Set([...row.factors.client.factors, ...row.factors.provider.factors]);
  const planFactors = allPlanFactors.filter((f) => departmentIds.has(f.department.id) || factorIds.has(f.id));

  const planFactorKeys = planFactors.map(({ name }) => name.toLowerCase().trim());
  const departmentFactors = useDepartmentFactors(row.supplier.product).filter(
    (d) => !planFactorKeys.includes(d.name.toLowerCase().trim()),
  );

  return {
    planFactors,
    departmentFactors,
  };
}

// @TODO autofucs dependning on the dircetion
export function Edit({
  direction,
  row,
  ...props
}: RenderEditCellProps<GridRow, GridSummaryRow> & {
  direction: keyof RowFactors;
}): ReactNode {
  invariant(row.supplier);
  const { planFactors, departmentFactors } = useRowFactors(row);
  const department = row.supplier.product.department;
  const controller = usePlanController();

  return (
    <DialogEditor
      testId="factor-edit"
      dialogHeader="Row discounts & factors"
      initialValues={row.factors}
      submitHandler={async ({
        client,
        provider,
      }: {
        client: {
          discounts: null | number[];
          factors: (number | DepartmentFactor)[];
        };
        provider: {
          discounts: null | number[];
          factors: (number | DepartmentFactor)[];
        };
      }) => {
        // FIXME: we create plan factors from departemnt factors
        const createdFactors: Record<number, number> = {};
        for (let i = 0; i < client.factors.length; i++) {
          const factor = client.factors[i];
          if (typeof factor !== 'number') {
            if (!createdFactors[factor.id]) {
              createdFactors[factor.id] = (
                await controller.createPlanFactorFromDepartmentFactor({
                  ...factor,
                  department,
                })
              ).id;
            }
            client.factors[i] = createdFactors[factor.id];
          }
        }

        for (let i = 0; i < provider.factors.length; i++) {
          const factor = provider.factors[i];
          if (typeof factor !== 'number') {
            if (!createdFactors[factor.id]) {
              createdFactors[factor.id] = (
                await controller.createPlanFactorFromDepartmentFactor({
                  ...factor,
                  department,
                })
              ).id;
            }
            provider.factors[i] = createdFactors[factor.id];
          }
        }

        // FIXME: no null in discount set
        const values = {
          client: {
            factors: client.factors,
            discounts: client.discounts ? client.discounts.filter((v: any) => v !== null) : null,
          },
          provider: {
            factors: provider.factors,
            discounts: provider.discounts ? provider.discounts.filter((v: any) => v !== null) : null,
          },
        };

        return controller.formRequest({
          method: 'PUT',
          url: `plan/rows/${row.id}/factors/`,
          body: values,
        });
      }}
      {...props}
    >
      <fieldset>
        <legend>Client discounts</legend>
        <div className="form-row">
          <DecimalField name="client.discounts[0]" placeholder="Discount 1" className="col" size="sm" precision={6} />
          <DecimalField name="client.discounts[1]" placeholder="Discount 2" className="col" size="sm" precision={6} />
          <DecimalField name="client.discounts[2]" placeholder="Discount 3" className="col" size="sm" precision={6} />
        </div>
      </fieldset>
      <fieldset>
        <legend>Provider discount</legend>
        <div className="form-row">
          <DecimalField name="provider.discounts[0]" placeholder="Discount 1" className="col" size="sm" precision={6} />
          <DecimalField name="provider.discounts[1]" placeholder="Discount 2" className="col" size="sm" precision={6} />
          <DecimalField name="provider.discounts[2]" placeholder="Discount 3" className="col" size="sm" precision={6} />
        </div>
      </fieldset>
      <FactorSelector planFactors={planFactors} departmentFactors={departmentFactors} controller={controller} />
    </DialogEditor>
  );
}
