import { useCallback, createContext, useContext } from 'react';
import { useRequest } from '@ff-it/api';
import { useEntity } from 'components';
import invariant from 'tiny-invariant';
import { actionErrorAndThrow } from 'utilities';
import type { Group } from 'modules/core/types';
import { api } from 'services';

interface PermissionContextType {
  loading: boolean;
  permissions: Record<number, true>;
  toggle: (id: number, enable: boolean) => Promise<void>;
}

export const PermissionContext = createContext<undefined | PermissionContextType>(undefined);
export function usePermissionContext(): PermissionContextType {
  const ctx = useContext(PermissionContext);
  invariant(ctx);
  return ctx;
}

const empty = {};

export function useCreatePermissionsContext(): PermissionContextType {
  const { endpoint } = useEntity<Group>();
  const url = `${endpoint}permissions/`;

  const { loading, data, setState } = useRequest<Record<number, true>, unknown>({
    url,
    method: 'GET',
  });
  const permissions: Record<number, true> = data || empty;

  // biome-ignore lint/correctness/useExhaustiveDependencies: endpoint should be enough
  const toggle = useCallback(
    async (id: number, enable: boolean) => {
      setState((state) => ({ ...state }));
      const result = await api.request({
        url,
        method: enable ? 'POST' : 'DELETE',
        body: {
          id,
        },
      });

      if (result.ok) {
        setState({
          loading: false,
          data: result.data,
          error: undefined,
          success: result,
        } as any);
      } else {
        actionErrorAndThrow(result);
      }
    },
    [endpoint],
  );

  return {
    loading,
    permissions,
    toggle,
  };
}
