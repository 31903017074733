import { ReactElement, ReactNode, RefObject, createContext, useContext, useMemo } from 'react';
import type { KeyedMutator } from 'swr';
import type { Fetcher } from 'services';
import invariant from 'tiny-invariant';
import { Operation, applyPatch } from 'fast-json-patch';
import type { PlanRow } from 'modules/campaign/row';
import type { ProductKind } from 'types';
import { initialRowValues, initialGroupValues } from './initialValues';
import type { GridHandle } from './Grid';
import type { MoveSet, MoveType, PlanState } from './types';
import type { BlockDetails, BudgetGroup, PlanFactor, PlanResolution, PlanStrategyType } from '../../types';
import { getDRFFormError, SubmissionErrors } from '@ff-it/form';
import { RequestArgs, RequestFailure } from '@ff-it/api';
import { actionErrorOrThrow, parseAttachmentFilename } from 'utilities';
import { saveAs } from 'file-saver';
import { PlanStrategy } from '../../strategy';
import { toast } from 'react-toastify';
import { RowOrGroup } from './Grid/types';
import { RowsChangeData } from 'components/DataGrid';
import { DepartmentFactor } from 'modules/supplier/factors';
import { EmbeddedDepartment } from 'modules/supplier/department/types';
import { mutate as mutateGlobal } from 'swr';
import equal from 'fast-deep-equal';
import { cancelExport } from '../../strategy/type';

function applyChanges(current: PlanState | undefined, patch: Operation[]): PlanState {
  invariant(current);
  // FIXME:
  // It's either deep clone or mutate in place.
  // Neither works for us wee track row identities in useGridRows
  const { factors, ...rest } = applyPatch(current, patch, false, false).newDocument;
  return {
    factors: equal(current.factors, factors) ? current.factors : [...factors],
    ...rest,
  };
}

function handleError(error: RequestFailure<any>): void {
  actionErrorOrThrow(error as RequestFailure<any>);
}

function handleFormError(error: RequestFailure<any>): SubmissionErrors | void {
  const formError = getDRFFormError(error as RequestFailure<any>);
  if (formError) {
    return formError;
  }
  actionErrorOrThrow(error as RequestFailure<any>);
}

export const SUMS_KEY = '_SUMS';

export type PlanControllerProps = {
  planning: boolean;
  locked: boolean;
  strategy: PlanStrategy;
  mutate: KeyedMutator<PlanState>;
  requestHandler: Fetcher;
  gridRef: RefObject<GridHandle>;
};

export class PlanController {
  public readonly planning: boolean;
  public readonly locked: boolean;
  public readonly strategy: PlanStrategy;
  private mutate: KeyedMutator<PlanState>;
  private api: Fetcher;
  public readonly gridRef: RefObject<GridHandle>;
  constructor({ planning, locked, strategy, mutate, requestHandler, gridRef }: PlanControllerProps) {
    this.planning = planning;
    this.locked = locked;
    this.strategy = strategy;
    this.mutate = mutate;

    this.api = (async (args) => {
      // FIXME
      const res = await requestHandler(args);
      args.method !== 'GET' && mutateGlobal(SUMS_KEY);
      return res;
    }) as Fetcher;

    this.gridRef = gridRef;
  }

  /**
   * This is what is called for editors that call onRowChange and commit
   */
  public onRowsChange = async (
    updatedRows: RowOrGroup[],
    { indexes, column }: RowsChangeData<RowOrGroup, any>,
  ): Promise<void> => {
    // should be single row
    invariant(indexes.length === 1);
    const scheduledRow: RowOrGroup = updatedRows[indexes[0]];
    if (scheduledRow._isGroup) {
      await this.mutate(
        async (currentData) =>
          applyChanges(
            currentData,
            await this.api<Operation[]>({
              method: 'PUT',
              url: `plan/groups/${scheduledRow.id}/`,
              body: scheduledRow,
            }),
          ),
        {
          optimisticData(currentData) {
            invariant(currentData);
            return {
              ...currentData,
              groups: currentData.groups.map((currentRow) =>
                currentRow.id === scheduledRow.id ? { ...scheduledRow, modified_at: new Date().getTime() } : currentRow,
              ),
            };
          },
          revalidate: false,
          rollbackOnError: true,
        },
      );
    } else {
      let field = column.key as keyof PlanRow;

      switch (column.key) {
        case 'smart':
          // FIXME
          field = scheduledRow.kind === 'SMART' ? 'rate' : 'applied_to';
          break;
      }

      const payload = scheduledRow[field];

      await this.mutate(
        async (currentData) =>
          applyChanges(
            currentData,
            await this.api<Operation[]>({
              method: 'PUT',
              url: `plan/rows/${scheduledRow.id}/${field}/`,
              body: payload,
            }),
          ),
        {
          optimisticData: (currentData) => {
            invariant(currentData);
            return {
              ...currentData,
              rows: currentData.rows.map((currentRow) =>
                currentRow.id === scheduledRow.id
                  ? { ...currentRow, [field]: payload, modified_at: new Date().getTime() / 1000 }
                  : currentRow,
              ),
            };
          },
          revalidate: false,
          rollbackOnError: true,
        },
      );
    }
  };

  public addRow = async (kind: ProductKind): Promise<PlanRow> => {
    return await this.mutate(
      async (currentData) => {
        const data = await this.api<Operation[]>({
          method: 'POST',
          url: `plan/rows/`,
          body: {
            ...initialRowValues,
            kind,
          },
        });
        return applyChanges(currentData, data);
      },
      { revalidate: false },
    ).then((currentData) => {
      invariant(currentData);
      // FIXME: max id for last row
      const created = currentData.rows.reduce((prev, current) => {
        return prev.id > current.id ? prev : current;
      });
      this.gridRef.current?.selectCell(`row-${created.id}`);
      return created;
    });
  };

  public copyRow = async (row: PlanRow, scrollToRow = true): Promise<PlanRow> => {
    return await this.mutate(
      async (currentData) => {
        const data = await this.api<Operation[]>({
          method: 'POST',
          url: `plan/rows/`,
          body: this.strategy.copyRow(row),
        });
        return applyChanges(currentData, data);
      },
      { revalidate: false },
    ).then((currentData) => {
      invariant(currentData);
      // FIXME: max id for last row
      const created = currentData.rows.reduce((prev, current) => {
        return prev.id > current.id ? prev : current;
      });
      scrollToRow && this.gridRef.current?.selectCell(`row-${created.id}`);
      return created;
    });
  };

  public removeRow = async (id: number): Promise<void> => {
    await this.mutate(
      async (currentData) => {
        const data = await this.api<Operation[]>({ method: 'DELETE', url: `plan/rows/${id}/` });
        invariant(data.length > 0);
        return applyChanges(currentData, data);
      },
      {
        optimisticData(currentData) {
          invariant(currentData);
          return {
            ...currentData,
            rows: currentData.rows.filter((currentRow) => currentRow.id !== id),
          };
        },
        revalidate: false,
        rollbackOnError: true,
      },
    );
  };

  private mutateRequest = async (args: RequestArgs): Promise<void> => {
    const data = await this.api<Operation[]>(args);
    await this.mutate(async (currentData) => applyChanges(currentData, data), {
      revalidate: false,
    });
  };

  public formRequest = async (args: RequestArgs): Promise<SubmissionErrors | void> =>
    this.mutateRequest(args).catch(handleFormError);

  public actionRequest = async (args: RequestArgs): Promise<void> => this.mutateRequest(args).catch(handleError);

  public move = async (payload: {
    move_set: MoveSet;
    source_id: number;
    move_type: MoveType;
    target_id: number;
  }): Promise<void> => this.actionRequest({ method: 'POST', url: `plan/move/`, body: payload });

  public addFactor = async (payload: Partial<PlanFactor>): Promise<SubmissionErrors | void> =>
    this.formRequest({ method: 'POST', url: `plan/factors/`, body: payload });

  public createPlanFactorFromDepartmentFactor = async (
    payload: DepartmentFactor & { department: EmbeddedDepartment },
  ): Promise<PlanFactor> => {
    const data = await this.api<Operation[]>({
      method: 'POST',
      url: `plan/factors/`,
      body: payload,
    });
    return await this.mutate(async (currentData) => applyChanges(currentData, data), {
      revalidate: false,
    })
      .catch(handleError)
      .then((currentData) => {
        invariant(currentData);
        // FIXME: max id for
        const created = currentData.factors.reduce((prev, current) => {
          return prev.id > current.id ? prev : current;
        });
        return created;
      });
  };

  public updateFactor = async (id: number, payload: Partial<PlanFactor>): Promise<SubmissionErrors | void> =>
    this.formRequest({ method: 'PUT', url: `plan/factors/${id}/`, body: payload });

  public removeFactor = async (id: number): Promise<void> =>
    this.actionRequest({ method: 'DELETE', url: `plan/factors/${id}/` });

  // groups
  public addGroup = async (): Promise<BudgetGroup> => {
    return await this.mutate(
      async (currentData) => {
        const data = await this.api<Operation[]>({
          method: 'POST',
          url: `plan/groups/`,
          body: initialGroupValues,
        });
        return applyChanges(currentData, data);
      },
      { revalidate: false },
    ).then((currentData) => {
      invariant(currentData);
      // FIXME: max id for last row
      const created = currentData.groups.reduce((prev, current) => {
        return prev.id > current.id ? prev : current;
      });
      this.gridRef.current?.selectCell(`group-${created.id}`);
      return created;
    });
  };

  public removeGroup = async (id: number): Promise<void> => {
    await this.mutate(
      async (currentData) => {
        const data = await this.api<Operation[]>({
          method: 'DELETE',
          url: `plan/groups/${id}/`,
        });
        return applyChanges(currentData, data);
      },
      {
        optimisticData(currentData) {
          invariant(currentData);
          return {
            ...currentData,
            groups: currentData.groups.filter((currentRow) => currentRow.id !== id),
          };
        },
        revalidate: false,
        rollbackOnError: true,
      },
    );
  };

  public setResolution = async (plan_resolution: PlanResolution): Promise<void> => {
    await this.mutate(
      this.api<PlanState>({
        method: 'PATCH',
        url: `plan/`,
        body: { plan_resolution },
      }),
      { revalidate: false },
    );
  };

  public setStrategy = async (plan_strategy: PlanStrategyType): Promise<void> => {
    await this.mutate(
      this.api<PlanState>({
        method: 'PATCH',
        url: `plan/`,
        body: { plan_strategy },
      }),
      { revalidate: false },
    );
  };

  public rowExport = async (): Promise<void> => {
    let filename;
    const blob = await this.api<Blob>({
      method: 'GET',
      url: 'export/',
      headers: { Accept: '*/*' },
      // FIXME: we really need a better api client
      after: (r) => {
        if (r.response) {
          filename = parseAttachmentFilename(r.response.headers);
        }
      },
    });
    saveAs(blob, filename);
  };

  public planExport = async (planState: PlanState): Promise<void> => {
    const exportOptions = await this.strategy.exportPlanOptions(planState);
    if (exportOptions === cancelExport) {
      toast.warn('Export canceled');
      return;
    }
    let filename;
    const blob = await this.api<Blob>({
      method: 'GET',
      queryParams: exportOptions,
      url: 'plan/xlsx/',
      headers: { Accept: '*/*' },
      // FIXME: we really need a better api client
      after: (r) => {
        if (r.response) {
          filename = parseAttachmentFilename(r.response.headers);
        }
      },
    });
    saveAs(blob, filename);
  };

  public codeImport = async ({ block }: { block: BlockDetails }): Promise<SubmissionErrors | void> => {
    try {
      const data = await this.api<PlanState>({
        url: `plan/rows/import/${block.id}/`,
        method: 'POST',
      });
      await this.mutate(data, { revalidate: false });
      toast.success('Rows have been imported');
    } catch (error) {
      const formError = getDRFFormError(error as RequestFailure<any>);
      if (formError) {
        return formError as any; // ??
      }
      actionErrorOrThrow(error as RequestFailure<any>);
    }
  };

  public planImport = async (values: any): Promise<SubmissionErrors | void> => {
    const fd = new FormData();
    for (const [key, value] of Object.entries(values)) {
      if (value) {
        fd.append(key, value as any);
      }
    }
    try {
      const data = await this.api<PlanState>({
        url: 'plan/xlsx/',
        method: 'POST',
        body: fd,
      });
      await this.mutate(data, { revalidate: false });
      toast.success('Plan has been imported');
    } catch (error) {
      const formError = getDRFFormError(error as RequestFailure<any>);
      if (formError) {
        return formError as any; // ??
      }
      actionErrorOrThrow(error as RequestFailure<any>);
    }
  };
}

const PlanControllerContext = createContext<PlanController | undefined>(undefined);

export function PlanControllerProvider({
  children,
  ...props
}: PlanControllerProps & { children: ReactNode }): ReactElement {
  // biome-ignore lint/correctness/useExhaustiveDependencies: should be fine
  const controller = useMemo(() => new PlanController(props), [props.requestHandler]);
  return <PlanControllerContext.Provider value={controller}>{children}</PlanControllerContext.Provider>;
}

export function usePlanController(): PlanController {
  return useContext(PlanControllerContext)!;
}
