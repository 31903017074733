import type { ReactElement } from 'react';
import type { EmbeddedUser } from 'modules/core/types';
import { TextField, DatePickerField, DateRangePickerField, CheckField, useField } from '@ff-it/form';
import { PayerVatRateField, IncomeTypeField, PaymentDateField } from 'components';
import { CompanyField } from 'modules/core/company/components';
import type { InvoiceType } from 'modules/invoicing/common/types';

function IssuerField(): ReactElement {
  const {
    input: { value },
  } = useField<EmbeddedUser>('issued_by', { subscription: { value: true } });
  const name = value ? `${value.first_name} ${value.last_name}` : undefined;

  return <CheckField name="hide_issuer" label="Hide issuer" className="col-md-4" variant="switch" help={name} />;
}

type FieldsProps = {
  lockType: boolean;
  lockPayer: boolean;
  type: InvoiceType;
};

export function Fields({ lockType, lockPayer, type }: FieldsProps): ReactElement {
  return (
    <>
      <div className="form-row">
        <IncomeTypeField name="type" label="Type" className="col-md-3" required disabled={lockType} />
        <CompanyField
          name="payer"
          label="Payer"
          className="col-md-3"
          required
          disabled={lockPayer}
          includeBranches={true}
        />
        <DatePickerField name="checkout_date" label="Invoice date" className="col-md-3" required />
        <PaymentDateField name="payment_date" label="Payment date" className="col-md-3" required />
      </div>
      <div className="form-row">
        <TextField name="comment" label="Comment" className="col-md-4" autosize multiline />
        {type === 'INVOICE' && (
          <>
            <DateRangePickerField fromName="date_from" toName="date_to" label="Service period" className="col-md-4" />
            <TextField name="campaign_title" label="Campaign" className="col-md-4" help="Leave empty to use linked" />
          </>
        )}
      </div>
      <div className="form-row">
        <PayerVatRateField label="VAT rate" className="col-md-1 mr-3" required />
        <IssuerField />
      </div>
    </>
  );
}
