import { createConfirmDialog, EntityAction } from 'components';
import { parseISO } from 'date-fns';
import type { CampaignPeriod } from 'modules/campaign/campaign/types';
import {
  cancelBlockOptions,
  CreateBlockDialog,
  MaybeUpdateBlockDialog,
  MotivationDialog,
  RemoveBlockDialog,
  UpdateBlockDialog,
} from '../dialogs';
import type { BlockDetails } from '../types';
import permissions from 'modules/campaign/block/permissions';
import { checkPerm } from 'core/permissions';
import { AuthState, Fetcher } from 'services';
import { openModal } from '@ff-it/ui';
import { getDRFFormError } from '@ff-it/form';
import { RequestFailure } from '@ff-it/api';
import { toast } from 'react-toastify';

type UseManagerAactionArgs = {
  // FIXME: fecher
  fetcher: Fetcher;
  campaignId: number;
  campaignPeriod: CampaignPeriod;
  onRemove?: (block: BlockDetails) => void;
  onAdd?: (block: BlockDetails) => void;
};

export function useManagerActions({
  fetcher,
  campaignId,
  campaignPeriod,
  onAdd,
  onRemove,
}: UseManagerAactionArgs): EntityAction<BlockDetails>[] {
  return [
    ({ item, endpoint }) => ({
      action: 'edit',
      button: {
        children: 'Update brief',
        icon: 'pencil',
        variant: 'outline-secondary',
      },
      promise: () =>
        openModal<BlockDetails>(
          (props) => (
            <UpdateBlockDialog
              {...props}
              block={item}
              campaignPeriod={campaignPeriod}
              submitHandler={async (values) => {
                try {
                  props.onSubmit(
                    await fetcher<BlockDetails>({
                      url: endpoint,
                      method: 'PATCH',
                      body: values,
                    }),
                  );
                } catch (e) {
                  const err = getDRFFormError(e as RequestFailure<unknown>);
                  if (err) {
                    return err;
                  }
                  throw e;
                }
              }}
              remove={
                onRemove
                  ? async (): Promise<any> => {
                      const res = await openModal<BlockDetails>((props) => (
                        <RemoveBlockDialog {...props} block={item} />
                      ));
                      if (res) {
                        onRemove(res);
                        toast.success('Block has been removed.', { toastId: 'remove-block-toast' });
                        return res;
                      }
                    }
                  : undefined
              }
            />
          ),
          { className: 'right', canDismiss: false },
        ),
      successMessage: 'Block has been updated.',
      visible: ({ state, role: { manage } }: BlockDetails) => manage && (state === 'NEW' || state === 'PLANNING'),
      permKey: null, // we look at role
    }),
    ({ item }) =>
      onAdd
        ? {
            action: 'copy',
            button: {
              children: 'Copy brief',
              icon: 'copy',
            },
            successMessage: 'Block has been updated.',
            visible: ({ role: { manage } }: BlockDetails) => manage,
            permKey: null, // we look at role
            promise: () =>
              openModal<BlockDetails>(
                (props) => (
                  <CreateBlockDialog
                    {...props}
                    campaignId={campaignId}
                    type={item.type}
                    campaignPeriod={campaignPeriod}
                    copyValues={{
                      ...item,
                      id: undefined,
                      date_from: null,
                      date_to: null,
                      deadline: null,
                      brief_attachments: [],
                    }}
                  />
                ),
                { className: 'right', canDismiss: false },
              ).then((res) => {
                if (res) {
                  onAdd(res);
                  toast.success('Block has been added.', { toastId: 'add-block-toast' });
                }
              }),
          }
        : null,
    {
      action: 'assign',
      button: {
        variant: 'primary',
        children: 'Assign plan',
        icon: 'paper-plane',
      },
      successMessage: 'Plan has been assigned.',
      visible: ({ state, role: { manage } }: BlockDetails) => manage && state === 'NEW',
      permKey: null, // we look at role
    },
    {
      action: 'approve',
      button: {
        variant: 'success',
        children: 'Approve plan',
        icon: 'thumbs-up',
      },
      successMessage: 'Plan has been approved.',
      visible: ({ state, role: { manage } }: BlockDetails) => manage && state === 'SUBMITTED',
      permKey: null, // we look at role
    },
    ({ item: { sums } }) => {
      const has_remainder = !sums?.all_bound;

      return {
        action: 'done',
        button: {
          children: 'Mark done',
          icon: 'check',
          variant: 'outline-success',
          disabled: has_remainder,
          title: has_remainder ? 'Invalid bound state' : undefined,
        },
        dialog: createConfirmDialog(),
        successMessage: 'Plan has been marked as done.',
        visible: ({ state, role: { manage }, date_to }: BlockDetails) =>
          manage && state === 'APPROVED' && parseISO(date_to) < new Date(),
        permKey: null, // we look at role
      };
    },
    ({ item, endpoint }) => ({
      action: 'reject',
      button: {
        children: 'Reject plan',
        icon: 'thumbs-down',
        variant: 'outline-danger',
      },
      lock: false,
      promise: () =>
        openModal<BlockDetails>(
          ({ onSubmit }) => (
            <MaybeUpdateBlockDialog
              block={item}
              action="reject"
              campaignPeriod={campaignPeriod}
              submitHandler={async (values) => {
                try {
                  onSubmit(
                    await fetcher<BlockDetails>({
                      url: `${endpoint}reject/`,
                      method: 'POST',
                      body: values,
                    }),
                  );
                } catch (e) {
                  const err = getDRFFormError(e as RequestFailure<unknown>);
                  if (err) {
                    return err;
                  }
                  throw e;
                }
              }}
            />
          ),
          { canDismiss: false },
        ),
      successMessage: 'Block has been rejected',
      visible: ({ state, role: { manage } }) => manage && state === 'SUBMITTED',
      permKey: null, // we look at role
    }),
    ({ item, endpoint }) => ({
      action: 'replan',
      button: {
        children: 'Replan',
        icon: 'rotate-left',
        variant: 'outline-danger',
      },
      lock: false,
      promise: () =>
        openModal<BlockDetails>(
          ({ onSubmit }) => (
            <MaybeUpdateBlockDialog
              block={item}
              action="reject"
              campaignPeriod={campaignPeriod}
              submitHandler={async (values) => {
                try {
                  onSubmit(
                    await fetcher<BlockDetails>({
                      url: `${endpoint}replan/`,
                      method: 'POST',
                      body: values,
                    }),
                  );
                } catch (e) {
                  const err = getDRFFormError(e as RequestFailure<unknown>);
                  if (err) {
                    return err;
                  }
                  throw e;
                }
              }}
            />
          ),
          { canDismiss: false },
        ),
      successMessage: 'Block has been returned to planning',
      visible: ({ state, role: { manage } }) => manage && state === 'APPROVED',
      permKey: null, // we look at role
    }),
    {
      action: 'undo',
      button: {
        children: 'Not done',
        variant: 'outline-danger',
        icon: 'rotate-left',
      },
      successMessage: 'Plan has been marked as not done.',
      visible: ({ state }: BlockDetails) => state === 'DONE',
      permKey: (sess: AuthState): boolean => checkPerm(sess, permissions.undo),
    },
    {
      action: 'cancel',
      button: {
        children: 'Cancel',
        variant: 'outline-danger',
        icon: 'trash-can-xmark',
      },
      lock: false,
      requestDialog: () =>
        openModal((props) => <MotivationDialog {...props} label="Motivation to cancel" options={cancelBlockOptions} />),
      successMessage: 'Plan has been canceled.',
      visible: ({ state, role: { manage } }: BlockDetails) => manage && state !== 'CANCELED',
      permKey: null, // we look at role
    },
    {
      action: 'restore',
      button: {
        children: 'Restore canceled',
        // variant: 'outline-danger',
        icon: 'trash-can-undo',
      },
      successMessage: 'Plan has been restored.',
      visible: ({ state, role: { manage } }: BlockDetails) => manage && state === 'CANCELED',
      permKey: null, // we look at role
    },
  ];
}
