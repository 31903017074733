import type { ListCampaignRow } from 'modules/campaign/row';
import { SupplierTitle } from 'modules/supplier/supplier/components';
import { date } from 'components/columns';
import { BoundStates, ClientTitle, EnterpriseLink, Icon } from 'components';
import { sepFormat } from 'utilities';
import { TableColumns } from '@ff-it/ui';

const sumProps = {
  width: 120,
  cellProps: {
    textAlign: 'end' as const,
  },
};

export const columns: TableColumns<ListCampaignRow> = [
  {
    // content: 'Row',
    key: 'id',
    width: 25,
    cellProps: {
      className: 'px-0 text-center',
    },
    render: ({ id, block: { id: blockId, campaign } }: ListCampaignRow) => (
      <EnterpriseLink to={`/planning/campaigns/${campaign.id}/planning/${blockId}?rowId=${id}`}>
        <Icon size="sm" icon="link" />
      </EnterpriseLink>
    ),
    sortable: false,
  },
  {
    content: 'Block',
    key: 'block.code',
    width: 120,
    render: ({ block: { id, code, campaign } }: ListCampaignRow) => (
      <EnterpriseLink to={`/planning/campaigns/${campaign.id}/planning/${id}`}>{code}</EnterpriseLink>
    ),
    sortable: true,
  },
  {
    content: 'Type',
    key: 'block.type',
    sortable: true,
  },
  {
    content: 'Title',
    key: 'block.title',
    sortable: true,
  },
  {
    content: 'Client',
    key: 'block.client',
    render: ({ block: { campaign } }) => <ClientTitle {...campaign} />,
  },
  {
    content: 'Supplier',
    key: 'supplier',
    cellProps: {
      className: 'py-1',
    },
    render: ({ supplier }) => (supplier ? <SupplierTitle {...supplier} /> : null),
  },
  date('Date from', 'sums.date_from' as any),
  date('Date to', 'sums.date_to' as any),
  {
    ...sumProps,
    content: 'Client total',
    key: 'sums.target_income',
    render: ({ sums }) => sepFormat(!sums.canceled ? sums.target_income : null),
  },
  {
    ...sumProps,
    content: 'Provider total',
    key: 'sums.target_expense',
    render: ({ sums }) => sepFormat(!sums.canceled ? sums.target_expense : null),
  },
  {
    width: 60,
    cellProps: {
      textAlign: 'center',
    },
    key: 'sums',
    render: ({ id, sums }: ListCampaignRow) => (!sums.canceled ? <BoundStates {...sums} row={id} /> : null),
  },
  {
    ...sumProps,
    content: 'Revenue',
    key: 'sums.revenue',
    render: ({ sums }) => sepFormat(!sums.canceled ? sums.revenue : null),
  },
];
