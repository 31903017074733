import type { ReactElement } from 'react';
import { Button, MenuItem, MenuList, Popover } from '@ff-it/ui';
import { createOptions } from 'options';
import { Icon } from 'components';
import type { PlanController } from '../usePlanController';
import type { PlanResolution } from 'modules/campaign/block/types';

const options = createOptions<PlanResolution>(['DAY', 'WEEK', 'MONTH']);

type ResolutionButtonProps = {
  resolution: PlanResolution;
  controller: PlanController;
};

export function ResolutionButton({ resolution: current, controller }: ResolutionButtonProps): ReactElement {
  return (
    <Popover
      interaction="click"
      placement="auto"
      placementOptions={{ allowedPlacements: ['bottom-start', 'bottom-end'] }}
      offsetOptions={6}
      content={
        <MenuList>
          {options.map(({ value, label }) => (
            <MenuItem
              key={value}
              active={value === current}
              onClick={() => controller.setResolution(value)}
              label={label}
              testId={`resolution-${value}`}
            />
          ))}
        </MenuList>
      }
    >
      <Button testId="resolution-toggle" variant="outline-secondary">
        <Icon icon="calendar-days" />
      </Button>
    </Popover>
  );
}
