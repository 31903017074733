import { useMemo, Dispatch, SetStateAction, ReactElement } from 'react';
import { parseISO } from 'date-fns';
import type { BlockDetails, BlockState } from 'modules/campaign/block/types';
import { Toolbar } from './Toolbar';
import type { CampaignDetails } from '../types';
import { EntityContextType } from 'components';
import { BlockGroup } from './BlockGroup';
import './CampaignOverview.scss';
import { Table, Th, Thead, Tr } from '@ff-it/ui';
import Big from 'big.js';
import { fmt, parseBig } from 'utilities';

const stateOrder: BlockState[] = ['NEW', 'PLANNING', 'SUBMITTED', 'APPROVED', 'DONE', 'CANCELED'];

interface BlocksProps {
  blocks: BlockDetails[];
  setBlocks: Dispatch<SetStateAction<BlockDetails[] | undefined>>;
  ctx: EntityContextType<CampaignDetails>;
}

export function CampaignOverview({ blocks, ctx, setBlocks }: BlocksProps): ReactElement {
  const {
    item: {
      id,
      date_from,
      date_to,
      role: { manage },
    },
  } = ctx;

  const campaignPeriod = useMemo(() => {
    return {
      minDate: parseISO(date_from),
      maxDate: parseISO(date_to),
    };
  }, [date_from, date_to]);

  const { add, remove, replace } = useMemo(
    () => ({
      add: (block: BlockDetails) => setBlocks((blocks) => [...(blocks as BlockDetails[]), block]),
      replace: (block: BlockDetails) =>
        setBlocks((blocks) => (blocks as BlockDetails[]).map((b) => (b.id === block.id ? block : b))),
      remove: (block: BlockDetails) =>
        setBlocks((blocks) => (blocks as BlockDetails[]).filter((b) => b.id !== block.id)),
    }),
    [setBlocks],
  );

  let budget = Big(0);
  let incomeTotal = Big(0);
  let expenseTotal = Big(0);
  let revenueTotal = Big(0);

  const groupedBlocks = blocks.reduce(
    (acc, block) => {
      if (block.state !== 'CANCELED') {
        budget = budget.plus(parseBig(block.budget));
        incomeTotal = incomeTotal.plus(parseBig(block.sums?.income_total));
        expenseTotal = expenseTotal.plus(parseBig(block.sums?.expense_total));
        revenueTotal = revenueTotal.plus(parseBig(block.sums?.revenue));
      }
      if (!acc[block.state]) {
        acc[block.state] = [];
      }
      acc[block.state].push(block);

      return acc;
    },
    {} as Record<BlockState, BlockDetails[]>,
  );

  return (
    <div className="container-fluid">
      {manage && <Toolbar campaignId={id} campaignPeriod={campaignPeriod} addBlock={add} />}
      <Table
        tableClassName="campaign-overview"
        fixed
        variant="dark"
        marginTop="sm"
        size="sm"
        fontSize="sm"
        bordered
        hover
      >
        <Thead>
          <Tr>
            <Th className="controls">{/* controls */}</Th>
            <Th className="state">{/* bound state */}</Th>
            <Th className="type">Type</Th>
            <Th className="code">Code</Th>
            <Th>Title</Th>
            <Th className="period">Period</Th>
            <Th>Planners</Th>
            <Th textAlign="end">Budget</Th>
            <Th textAlign="end" className="client" fontWeight="bold">
              Client total
            </Th>
            <Th textAlign="end" className="agency">
              Agency total
            </Th>
            <Th textAlign="end" className="revenue">
              Revenue
            </Th>
            <Th className="navigate"></Th>
          </Tr>
        </Thead>
        <Thead>
          <Tr variant="light">
            <Th colSpan={7} />
            <Th textAlign="end">{fmt(budget)}</Th>
            <Th textAlign="end" className="client">
              {fmt(incomeTotal)}
            </Th>
            <Th textAlign="end" className="agency">
              {fmt(expenseTotal)}
            </Th>
            <Th textAlign="end" className="revenue">
              {fmt(revenueTotal)}
            </Th>
            <Th></Th>
          </Tr>
        </Thead>

        {stateOrder.map((state) => {
          const stateBlocks = groupedBlocks[state];
          if (stateBlocks) {
            return (
              <BlockGroup
                campaignId={id}
                state={state}
                key={state}
                blocks={stateBlocks}
                collapse={state === 'CANCELED'}
                campaignPeriod={campaignPeriod}
                replace={replace}
                remove={remove}
                add={add}
                toggleLoading={ctx.setSpinning}
              />
            );
          }
          return null;
        })}
      </Table>
    </div>
  );
}
