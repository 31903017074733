import { ReactElement } from 'react';
import { Button, Dialog, DialogTrigger } from '@ff-it/ui';
import { Icon } from 'components';
import type { FactorDialogProps } from '../Factors/types';
import { FactorDialog } from '../Factors';

export function FactorsButton(props: FactorDialogProps): ReactElement {
  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="outline-secondary" testId="toggle-factors">
          Factors <Icon icon="sparkle" className="ml-1" />
        </Button>
      </DialogTrigger>
      <FactorDialog {...props} />
    </Dialog>
  );
}
