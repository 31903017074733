import { ReactElement } from 'react';
import { strategies } from 'modules/campaign/block/strategy';
import { type PlanStrategyType } from 'modules/campaign/block/types';
import { Button, Popover, MenuList, MenuItem } from '@ff-it/ui';
import { Icon } from 'components';
import { useMembership } from 'services';
import type { PlanController } from '../usePlanController';
import { ActivityType } from 'types';
import { PlanningConfig } from '../types';

type StrategyButtonProps = {
  strategy: PlanStrategyType;
  type: ActivityType;
  controller: PlanController;
  disabled?: boolean;
};

export function StrategyButton({ strategy, disabled, controller, type }: StrategyButtonProps): ReactElement {
  const planningConfig: PlanningConfig = useMembership().planning_config;
  const { available_strategies: available, lock } = planningConfig[type];

  return (
    <Popover
      interaction="click"
      placement="auto"
      placementOptions={{ allowedPlacements: ['bottom-start', 'bottom-end'] }}
      offsetOptions={6}
      content={
        <MenuList>
          {available.map((key) => (
            <MenuItem
              key={key}
              onClick={() => controller.setStrategy(key)}
              active={key === strategy}
              label={strategies[key].TITLE}
            ></MenuItem>
          ))}
        </MenuList>
      }
    >
      <Button
        variant="outline-secondary"
        testId="current-strategy"
        disabled={disabled || lock || available.length === 1}
      >
        {strategies[strategy].TITLE} <Icon className="ml-1" icon="table-columns" />
      </Button>
    </Popover>
  );
}
