import { ReactElement, createContext, useContext, useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CampaignDetails } from '../campaign/types';
import { Breadcrumb, EntityContext, ItemLoader, useEntity } from 'components';
import { BlockHeader } from './BlockHeader';
import { BlockDetails } from './types';
import { parseISO } from 'date-fns';
import { Fetcher, useSyncFetcher } from 'services';
import { RequestHandlerProvider } from './useRequestHandler';

const BlockFetcherContext = createContext<Fetcher | undefined>(undefined);

export function BlockProvider(): ReactElement {
  const { item: campaign } = useEntity<CampaignDetails>();
  const campaignPeriod = { minDate: parseISO(campaign.date_from), maxDate: parseISO(campaign.date_to) };

  const { block_id } = useParams<'block_id'>();
  invariant(block_id);
  const url = `campaign/blocks/${block_id}/`;

  /**
   * We want to protect our block and plan actions against the races and concurrent updates
   * We make them serialized by waiting for mutext, and mark them with etag
   */

  const fetcher = useSyncFetcher();
  // FIXME: don't cahce on mount. We want our fetceher to be in sync
  const _request = useRef(Date.now());

  return (
    <ItemLoader<BlockDetails> url={url} swrOptions={{ fetcher }} swrKey={{ url, method: 'GET', _request }}>
      {(ctx) => (
        <BlockFetcherContext.Provider value={fetcher}>
          <RequestHandlerProvider blockFetcher={fetcher} ctx={ctx}>
            <EntityContext.Provider value={ctx}>
              <BlockHeader
                block={ctx.item}
                endpoint={ctx.endpoint}
                campaignId={campaign.id}
                campaignPeriod={campaignPeriod}
              />
              <Breadcrumb>{ctx.item.code}</Breadcrumb>
              <Outlet />
            </EntityContext.Provider>
          </RequestHandlerProvider>
        </BlockFetcherContext.Provider>
      )}
    </ItemLoader>
  );
}

export function useBlockFetcher(): Fetcher {
  return useContext(BlockFetcherContext)!;
}
