import cx from 'clsx';
import { Fragment, ReactElement, ReactNode } from 'react';
import { RenderCellProps } from 'components/DataGrid';
import type { GridRow, GridSummaryRow } from '../../types';
import type { RowFactors } from 'modules/campaign/row';
import type { PlanFactor } from 'modules/campaign/block/types';
import { usePlanFactors } from '../../hooks';
import { factorLabel } from './styles.css';
import { wrapContainer } from '../style.css';
import { Box } from '@ff-it/ui';

function renderDiscounts(discounts: string[] | null, currentDiscount: string | null, isEditable: boolean): ReactNode {
  if (!discounts && currentDiscount) {
    return <Box className={wrapContainer} color="quarterary">{`${currentDiscount}%`}</Box>;
  }

  return discounts ? (
    <Box className={cx(wrapContainer, 'triple')}>
      {discounts.map((value, idx) => (
        <div key={idx}>{value}%</div>
      ))}
    </Box>
  ) : !isEditable ? (
    '—'
  ) : null;
}

function FactorLabel({ rate, direction }: { rate: string; direction: 'client' | 'provider' }): ReactElement {
  return <span className={cx(factorLabel, direction)}>+{rate}%</span>;
}

export function View({
  row,
  isCellEditable,
  direction,
}: RenderCellProps<GridRow, GridSummaryRow> & {
  direction: keyof RowFactors;
}): ReactNode {
  const planFactors = usePlanFactors();
  const value = row.factors[direction];
  const currentDiscount = direction === 'client' ? row.sums.client_discount_rate : row.sums.provider_discount_rate;

  const factorRates = value.factors.map((factorId) => (planFactors.find((v) => v.id === factorId) as PlanFactor).rate);
  const renderedDiscounts = renderDiscounts(value.discounts, currentDiscount, isCellEditable);
  if (renderedDiscounts || factorRates.length > 0) {
    return (
      <Box display="flex" alignItems="center">
        <div className={cx(wrapContainer, 'triple')}>
          {factorRates.map((rate, idx) => (
            <Fragment key={idx}>
              <FactorLabel rate={rate} direction={direction} />{' '}
            </Fragment>
          ))}
        </div>
        <Box textAlign="end" marginLeft="auto">
          {renderedDiscounts}
        </Box>
      </Box>
    );
  }
  return null;
}
