import { sepFormat } from 'utilities';
import { createColumn } from './createColumn';
import { DecimalEditor } from 'components/DataGrid';
import { right } from './style.css';

export const EstImpressionsColumn = createColumn(
  {
    key: 'est_impressions',
    name: 'Est. impr.',
    width: 96,
    className: right,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? sepFormat(props.row.est_impressions) : null),
    renderEdit: (props) => <DecimalEditor {...props} precision={2} normalize />,
  },
);
