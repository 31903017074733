import cx from 'clsx';
import type { RenderCellProps } from 'components/DataGrid';
import { useDraggable } from '../hooks';
import type { GridGroup, GridRow, GridSummaryRow } from '../types';
import { ReactElement } from 'react';
import { Action, ActionDropdown, DialogForm } from 'components';
import { createColumn } from './createColumn';
import { handleBtnClassname } from 'components/DND/style.css';
import { flush } from './style.css';
import { usePlanController } from '../../usePlanController';
import { openModal } from '@ff-it/ui';

import { TextField } from '@ff-it/form';
import { toast } from 'react-toastify';

export function RowHandle({ row }: RenderCellProps<GridRow, GridSummaryRow>): ReactElement {
  const controller = usePlanController();
  // dragable, vs has actions
  const locked = !controller.planning || row.sums.is_locked;
  const actions: Action[] = [
    {
      action: 'remove',
      button: {
        children: 'Delete',
        disabled: locked,
        variant: 'danger',
        icon: 'xmark',
        onClick: async () => {
          await controller.removeRow(row.id);
          toast.warn(`Deleted row!`);
        },
      },
    },
    {
      action: 'comment',
      button: {
        children: 'Comment',
        disabled: row.config.comment.locked, // we can comment even for locked plan or row
        icon: 'comment-dots',
        onClick: () =>
          openModal((props) => (
            <DialogForm
              {...props}
              initialValues={{ comment: row.comment }}
              submitHandler={({ comment }: any) =>
                controller.formRequest({
                  method: 'PUT',
                  url: `plan/rows/${row.id}/comment/`,
                  body: comment,
                })
              }
            >
              <TextField name="comment" label="Comment" multiline autosize required />
            </DialogForm>
          )),
      },
    },
    {
      action: 'copy',
      button: {
        children: 'Copy',
        disabled: !controller.planning,
        icon: 'copy',
        onClick: async () => {
          await controller.copyRow(row);
          toast.success(`Copied row!`);
        },
      },
    },
  ];

  const [handleRef, isDragging] = useDraggable(row);
  return (
    <ActionDropdown
      testId={`${row._key}-actions`}
      buttonRef={handleRef}
      actions={actions}
      icon="grip-vertical"
      variant={null}
      size={null}
      menuFontSize="sm"
      className={cx(handleBtnClassname, 'row-handle', isDragging && 'dragging')}
    />
  );
}

export function GroupHandle({ row }: RenderCellProps<GridGroup, GridSummaryRow>): ReactElement {
  const controller = usePlanController();
  const locked = row.config.locked;
  // dragable, vs has actions

  const actions: Action[] = [
    {
      action: 'remove',
      button: {
        children: 'Delete',
        disabled: locked || !row.empty,
        icon: 'xmark',
        variant: 'danger',
        onClick: () => controller.removeGroup(row.id),
      },
    },
  ];

  const [handleRef, isDragging] = useDraggable(row);
  return (
    <ActionDropdown
      testId={`${row._key}-actions`}
      buttonRef={handleRef}
      actions={actions}
      icon="grip-vertical"
      variant={null}
      size={null}
      menuFontSize="sm"
      className={cx(handleBtnClassname, 'row-handle', isDragging && 'dragging')}
    />
  );
}

export const HandleColumn = createColumn(
  {
    key: 'handle',
    width: 30,
    frozen: true,
    className: flush,
  },
  {
    renderView: (props) => <RowHandle {...props} />,
  },
  {
    renderView: (props) => (props.row.id === 0 ? null : <GroupHandle {...props} />),
  },
);
