import { ReactElement } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { SessionProvider } from 'services';
import { ModalContainer } from '@ff-it/ui';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'components/ErrorBoundary';

export function Layout(): ReactElement {
  return (
    <ErrorBoundary>
      <ModalContainer />
      <SessionProvider>
        <ToastContainer
          theme="colored"
          position="top-center"
          autoClose={2000}
          hideProgressBar
          closeOnClick
          draggable={false}
          transition={Slide}
        />
        <Outlet />
      </SessionProvider>
    </ErrorBoundary>
  );
}
