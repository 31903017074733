import type { PlanRow } from 'modules/campaign/row';
import type { GridRow, GridSummaryRow, RowOrGroup, RowSetSummary } from '../types';
import { useMemo, useRef } from 'react';
import type { BudgetGroup } from 'modules/campaign/block/types';
import equal from 'fast-deep-equal';
import Big from 'big.js';
import { parseBig } from 'utilities';

type GroupByDictionary = Record<string, GridRow[]>;

function cmpRows(current: RowOrGroup, next: RowOrGroup): boolean {
  if (current.modified_at !== next.modified_at) {
    return true;
  }
  if (current._isGroup) {
    // submission updates locks, summary changes
    return !equal(current, next);
  } else {
    // FIXME: Price update toggles locks
    return !equal(current?.config, next.config);
  }
}

function diffCollection(current: RowOrGroup[], next: RowOrGroup[]): RowOrGroup[] {
  const indexed = current.reduce((acc: Record<string, RowOrGroup>, row) => {
    acc[row._key] = row;
    return acc;
  }, {});
  const res = [];

  for (let i = 0; i < next.length; i++) {
    const nextRow = next[i];
    const currentRow = indexed[nextRow._key];
    res.push(!currentRow || cmpRows(currentRow, nextRow) ? nextRow : currentRow);
  }
  return res;
}

const sums = {
  grossTotal: Big(0),
  clientTotal: Big(0),
  providerTotal: Big(0),
};

function calcGroup(rows: PlanRow[]): RowSetSummary {
  const { grossTotal, clientTotal, providerTotal } = rows.reduce(
    ({ grossTotal, clientTotal, providerTotal }: typeof sums, row) => ({
      grossTotal: grossTotal.plus(parseBig(row.sums.gross_total)),
      clientTotal: clientTotal.plus(parseBig(row.sums.target_income)),
      providerTotal: providerTotal.plus(parseBig(row.sums.target_expense)),
    }),
    sums,
  );

  const discountRate = grossTotal.eq(0) ? undefined : grossTotal.minus(clientTotal).div(grossTotal).mul(100);
  const revenue = clientTotal.minus(providerTotal);
  const margin = clientTotal.eq(0) ? undefined : revenue.div(clientTotal).mul(100);

  return {
    grossTotal,
    clientTotal,
    providerTotal,
    discountRate,
    revenue,
    margin,
  };
}

export function useGridRows(planGroups: BudgetGroup[], planRows: PlanRow[]): [RowOrGroup[], GridSummaryRow] {
  const ref = useRef<RowOrGroup[]>();

  return useMemo(() => {
    let { grossTotal, clientTotal, providerTotal } = sums;

    const groupedRows: GroupByDictionary = {};

    let flattenedRows: Array<RowOrGroup> = [];

    for (const row of planRows) {
      const groupId = row.group_id || 0;
      const groupKey = `group-${groupId}`;
      if (!(groupKey in groupedRows)) {
        groupedRows[groupKey] = [];
      }
      groupedRows[groupKey].push({ ...row, _isGroup: false, _key: `row-${row.id}`, _groupKey: groupKey });
    }

    // groups first
    for (const group of planGroups) {
      const groupKey = `group-${group.id}`;
      const children = groupedRows[groupKey] || [];
      const groupSummary = calcGroup(children);
      grossTotal = grossTotal.plus(groupSummary.grossTotal);
      clientTotal = clientTotal.plus(groupSummary.clientTotal);
      providerTotal = providerTotal.plus(groupSummary.providerTotal);

      flattenedRows.push({
        _isGroup: true,
        _key: groupKey,
        _groupKey: groupKey,
        childIds: children.map((c) => c.id),
        ...group,
        ...groupSummary,
        empty: children.length === 0,
      });

      if (groupKey in groupedRows) {
        flattenedRows.push(...children);
      }
    }

    // virtual, don't render if no rows
    // FIXME: probably hidde if no ungourped rows unless dragging
    if (planRows.length > 0) {
      const groupKey = 'group-0';

      const children = groupedRows[groupKey] || [];
      const groupSummary = calcGroup(children);
      grossTotal = grossTotal.plus(groupSummary.grossTotal);
      clientTotal = clientTotal.plus(groupSummary.clientTotal);
      providerTotal = providerTotal.plus(groupSummary.providerTotal);

      flattenedRows.push({
        _isGroup: true,
        _key: groupKey,
        _groupKey: groupKey,
        id: 0,
        budget: null,
        title: 'Ungrouped',
        config: { locked: true },
        childIds: children.map((c) => c.id),
        modified_at: 0.0,
        ...groupSummary,
        empty: children.length === 0,
      });
      flattenedRows.push(...children);
    }

    if (ref.current) {
      flattenedRows = diffCollection(ref.current, flattenedRows);
    }
    ref.current = flattenedRows;

    const discountRate = grossTotal.eq(0) ? undefined : grossTotal.minus(clientTotal).div(grossTotal).mul(100);
    const revenue = clientTotal.minus(providerTotal);
    const margin = clientTotal.eq(0) ? undefined : revenue.div(clientTotal).mul(100);

    return [
      flattenedRows,
      {
        grossTotal,
        clientTotal,
        providerTotal,
        discountRate,
        revenue,
        margin,
      },
    ];
  }, [planGroups, planRows]);
}
