import type { ReactElement, ReactNode, RefObject } from 'react';
import cx from 'clsx';
import { Icon } from '../ui/Icon';
import type { Action } from './Actions';
import { buttonVariantToIconColor } from 'utilities';
import type { IconProp } from 'components';
import { Button, ButtonVariant, MenuItem, MenuList, MenuListProps, MenuSeparator, Popover, Size } from '@ff-it/ui';
import { Placement } from '@floating-ui/react';

type ActionDropdownProps = {
  actions: (Action | '-')[];
  children?: ReactNode;
  icon?: IconProp;
  testId?: string;
  menuTestId?: string;
  className?: string;
  variant?: ButtonVariant | null;
  size?: Size | null;
  buttonRef?: RefObject<HTMLButtonElement>;
  portalId?: string;
  menuFontSize?: MenuListProps['fontSize'];
};

const allowedPlacements: Placement[] = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];

export function ActionDropdown({
  actions,
  children,
  icon,
  testId,
  className,
  variant,
  size,
  menuFontSize,
  buttonRef,
}: ActionDropdownProps): ReactElement | null {
  if (actions.length === 0) {
    return null;
  }
  return (
    <Popover
      interaction="click"
      placement="auto"
      placementOptions={{ allowedPlacements }}
      offsetOptions={6}
      content={
        <MenuList testId={testId ? `${testId}-menu` : undefined} fontSize={menuFontSize}>
          {actions.map((item, idx) => {
            if (item === '-') {
              return <MenuSeparator key={`separator-${idx}`} />;
            }

            const {
              action,
              button: { icon, className, children, variant, ...button } = {},
            } = item;

            return (
              <MenuItem
                key={action}
                testId={`action-${action}`}
                {...(button as any)}
                prefix={
                  icon ? <Icon icon={icon} className={cx(variant && buttonVariantToIconColor[variant])} /> : undefined
                }
                label={children}
              />
            );
          })}
        </MenuList>
      }
    >
      <Button testId={testId} className={className} variant={variant} size={size} ref={buttonRef}>
        {children}
        {icon && <Icon className={children ? 'ml-2' : undefined} icon={icon} />}
      </Button>
    </Popover>
  );
}
