import { sepFormat } from 'utilities';
import { createColumn } from './createColumn';
import { DecimalEditor } from 'components/DataGrid';
import { right } from './style.css';

export const EstClicksColumn = createColumn(
  {
    key: 'est_clicks',
    name: 'Est. clicks',
    width: 96,
    className: right,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? sepFormat(props.row.est_clicks) : null),
    renderEdit: (props) => <DecimalEditor {...props} precision={2} normalize />,
  },
);
