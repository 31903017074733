import { createColumn } from '../createColumn';

import { View } from './View';
import { Edit } from './Edit';

export const ClientFactorsColumn = createColumn(
  { key: 'client_factors', name: 'Client disc. / fact.', width: 116 },
  {
    renderView: (props) => (props.row.kind === 'SMART' ? null : <View {...props} direction="client" />),
    renderEdit: (props) => (props.row.kind === 'SMART' ? null : <Edit {...props} direction="client" />),
    displayCellContent: true,
  },
);

export const ProviderFactorsColumn = createColumn(
  { key: 'provider_factors', name: 'Provid. disc. / fact.', width: 116 },
  {
    renderView: (props) => (props.row.kind === 'SMART' ? null : <View {...props} direction="provider" />),
    renderEdit: (props) => (props.row.kind === 'SMART' ? null : <Edit {...props} direction="provider" />),
    displayCellContent: true,
  },
);
