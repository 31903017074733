import { ReactElement, useMemo } from 'react';
import { RouteObject, Navigate } from 'react-router';
import { columns } from './columns';
import { Form } from './Form';
import { ModelScene, CreateScene, EntityScene, DeleteScene, UpdateScene, IndexScene } from 'components';
import { Income } from './Income';
import { Expense } from './Expense';
import { useHasPerms } from 'core/permissions';
import { permissions } from './permissions';
import { Finance } from './Finance';
import { Filter } from 'modules/campaign/common/Filter';
import { History } from './History';
import { CampaignHeader } from 'modules/campaign/campaign';
import { Overview } from 'modules/campaign/campaign';
import { route as blockRoute } from 'modules/campaign/block';
import { useSession } from 'services';
import type { CampaignDetails } from 'modules/campaign/campaign/types';

export { permissions };

function CreateCampaignScene(): ReactElement {
  const [manage] = useHasPerms('campaign.manage_campaign');

  const sess = useSession();

  const initialValues = useMemo(
    () => ({
      title: '',
      client: null,
      payer: null,
      involved: [],
      ordered_by: null,
      brand: null,
      comment: '',
      date_from: null,
      date_to: null,
      manager: manage ? sess.user : null,
      blocks: [],
      attachments: [],
    }),
    [manage, sess.user],
  );

  return (
    <CreateScene initialValues={initialValues}>
      <Form />
    </CreateScene>
  );
}

function UpdateCampaignScene(): ReactElement {
  // Anyone with view permissions can view locked form
  const [hasChange] = useHasPerms(permissions.change);
  const disabled = !hasChange;

  return (
    <UpdateScene
      deletePath="../delete"
      createPath="../../create"
      perm={permissions.view}
      heading={null}
      disabled={disabled}
    >
      <Form />
    </UpdateScene>
  );
}

const model = {
  endpoint: 'campaign/campaigns/',
  title: 'Campaign',
  pluralTitle: 'Campaigns',
  entityTitle: (e: CampaignDetails) => e.title,
  permissions,
};

export const route: RouteObject = {
  path: 'campaigns',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          perm={permissions.view}
          storageKey="planning_campaigns"
          filterFields={<Filter scope="campaign" />}
        />
      ),
    },
    {
      path: 'create',
      element: <CreateCampaignScene />,
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <CampaignHeader />
        </EntityScene>
      ),
      children: [
        {
          path: 'planning',
          element: <Overview />,
        },
        blockRoute,
        {
          path: 'income/*',
          element: <Income />,
        },
        {
          path: 'expense/*',
          element: <Expense />,
        },
        {
          path: 'finance/*',
          element: <Finance />,
        },
        {
          path: 'update',
          element: <UpdateCampaignScene />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
        {
          path: 'history',
          element: <History />,
        },
        {
          index: true,
          element: <Navigate to="planning" />,
        },
      ],
    },
  ],
};
