import { ReactElement } from 'react';
import { Alert, Button } from '@ff-it/ui';
import { Link } from 'react-router-dom';
import { useRequest } from '@ff-it/api';

export function SynchronizationConflictsAlert(): ReactElement | null {
  const { data: conflictCount } = useRequest<number, unknown>({
    url: 'invoicing/expense/conflict-count',
    method: 'GET',
  });

  if (!conflictCount) {
    return null;
  }

  return (
    <Alert variant="warning">
      {conflictCount === 1 ? (
        <>
          There is <strong>1</strong> synchronization conflict.
        </>
      ) : (
        <>
          There are <strong>{conflictCount}</strong> synchronization conflicts.
        </>
      )}
      <Button className="ml-3" to="conflicts" component={Link}>
        Show
      </Button>
    </Alert>
  );
}
