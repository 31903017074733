import useSWR from 'swr';
import { createContext, useContext } from 'react';
import type { PlanFactor } from 'modules/campaign/block/types';
import type { DepartmentFactor } from 'modules/supplier/factors';
import type { EmbeddedProduct } from 'modules/supplier/products';

const PlanFactorsContext = createContext<PlanFactor[]>([]);

export const PlanFactorsProvider = PlanFactorsContext.Provider;

export function usePlanFactors(): PlanFactor[] {
  return useContext(PlanFactorsContext);
}

export function useDepartmentFactors(product: EmbeddedProduct): DepartmentFactor[] {
  const { data } = useSWR<DepartmentFactor[], unknown>({
    url: `supplier/departments/${product.department.id}/factors/`,
    method: 'GET',
    queryParams: { type: product.type },
  });

  return data || [];
}
