import { Column } from 'components/DataGrid';
import type { GridSummaryRow, RowOrGroup } from '../types';
import { eachMonthOfInterval, format, formatISO, startOfMonth } from 'date-fns';
import { createColumn } from '../columns/createColumn';
import { View } from './View';
import { Edit } from './Edit';

export function createMonthColums(interval: Interval): Column<RowOrGroup, GridSummaryRow>[] {
  return eachMonthOfInterval(interval).map((date) => {
    const month = startOfMonth(date);

    const monthString = formatISO(month, { representation: 'date' });
    const key = `month_${monthString}`;

    return createColumn(
      {
        key: key,
        name: format(month, 'MMM. yyyy'),
        width: 140,
      },
      {
        renderView: (props) => <View {...props} month={monthString} />,
        renderEdit: (props) => <Edit {...props} month={monthString} />,
        displayCellContent: true,
      },
    );
  });
}
