import { sepFormat } from 'utilities';
import { createColumn } from './createColumn';
import { DecimalEditor } from 'components/DataGrid';
import { right } from './style.css';

export const CTRColumn = createColumn(
  {
    key: 'ctr',
    name: 'CTR%',
    width: 68,
    className: right,
  },
  {
    renderView: (props) =>
      props.row.kind === 'MEDIA' ? (props.row.ctr ? `${sepFormat(props.row.ctr)}%` : null) : null,
    renderEdit: (props) => <DecimalEditor {...props} precision={2} normalize />,
  },
);
