import { ReactElement, ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  RenderProps,
} from '@ff-it/ui';
import { Form, FormError, SubmissionErrors, Submit } from '@ff-it/form';

type DialogFormProps = {
  dialogHeader?: ReactNode;
  children: ReactNode;
  decorators?: any;
  initialValues?: any;
  onRemove?: () => Promise<void>;
  className?: string;
};

export function DialogForm<R = void>({
  onDismiss,
  onSubmit,
  dialogHeader,
  children,
  decorators,
  initialValues,
  submitHandler,
  onRemove,
  className,
}: DialogFormProps &
  RenderProps<R> & {
    // submitHanlder and resolves to void. Or resolves to form values
    submitHandler?: R extends void ? (values: any) => Promise<SubmissionErrors | void> : never;
  }): ReactElement {
  return (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={async (values) => {
        if (submitHandler) {
          const err = await submitHandler(values);
          if (err) {
            return err;
          }
          onSubmit(undefined);
        } else {
          onSubmit(values as R);
        }
      }}
      initialValues={initialValues}
      renderErrors={false}
      decorators={decorators}
      className={className}
    >
      {dialogHeader && <DialogHeader title={dialogHeader} />}
      <DialogBody>
        <FormError />
        {children}
      </DialogBody>
      <DialogFooter>
        <Submit>Submit</Submit>
        <DialogClose className="ml-auto" />
        {onRemove && (
          <Button type="button" variant="outline-danger" className="ml-1" onClick={() => onRemove().then(onDismiss)}>
            Delete
          </Button>
        )}
      </DialogFooter>
    </Form>
  );
}

type RenderDialogFormProps = {
  onClose: () => void;
  testId?: string;
  submitHandler: (values: any) => Promise<SubmissionErrors | void>;
};

export function RenderDialogForm({ onClose, testId, ...rest }: RenderDialogFormProps & DialogFormProps): ReactElement {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent testId={testId}>
        <DialogForm {...rest} onDismiss={onClose} onSubmit={onClose} />
      </DialogContent>
    </Dialog>
  );
}
