import { ReactElement } from 'react';
import { EntityActions, EntityContext, useEntity } from 'components';
import type { CampaignPeriod } from '../../campaign/types';
import { usePlannerActions } from './usePlannerActions';
import { useManagerActions } from './useManagerActions';
import { useRequestHandler } from '../useRequestHandler';
import { BlockDetails } from '../types';

interface BlockActionsProps {
  campaignId: number;
  campaignPeriod: CampaignPeriod;
}

export function BlockActions({ campaignId, campaignPeriod }: BlockActionsProps): ReactElement {
  const requestHandler = useRequestHandler();
  const plannerActions = usePlannerActions();
  const managerActions = useManagerActions({
    campaignPeriod,
    fetcher: requestHandler,
    campaignId,
  });
  const ctx = useEntity<BlockDetails>();

  return (
    <div className="ml-auto my-auto">
      {/* FIXME: request handler has endpoint prepended */}
      <EntityContext.Provider value={{ ...ctx, endpoint: '' }}>
        <EntityActions actions={managerActions} className="btn-group-sm ml-3" group fetcher={requestHandler} />
        <EntityActions actions={plannerActions} className="btn-group-sm ml-3" group fetcher={requestHandler} />
      </EntityContext.Provider>
    </div>
  );
}
