import { useField } from '@ff-it/form';
import React from 'react';
import { Button, Check, Dialog, DialogTrigger } from '@ff-it/ui';
import cx from 'clsx';
import type { PlanFactor } from 'modules/campaign/block/types';
import type { DepartmentFactor } from 'modules/supplier/factors';
import { FactorOption } from '../../../Factors/FactorOption';
import { factorCheck, factorCheckContainer } from './styles.css';
import { Icon } from 'components';
import { FactorDialog } from '../../../Factors';
import { PlanController } from '../../../usePlanController';

type FactorCheckProps = {
  direction: 'client' | 'provider';
} & (
  | {
      scope: 'plan';
      factor: number;
    }
  | {
      scope: 'department';
      factor: DepartmentFactor;
    }
);

function FactorCheck({ scope, factor, direction }: FactorCheckProps): React.ReactElement {
  const {
    input: { value, onChange: controlledOnChange },
  } = useField<FactorCheckProps['factor'][], HTMLInputElement>(`${direction}.factors`, {
    subscription: { value: true },
  });

  const index =
    scope === 'plan' ? value.indexOf(factor) : value.findIndex((v) => (v as DepartmentFactor)?.id === factor.id);

  const checked = index !== -1;
  function onChange(e: any): void {
    controlledOnChange(e.target.checked ? value.concat(factor) : value.slice(0, index).concat(value.slice(index + 1)));
  }

  return (
    <div className={cx(factorCheckContainer, direction)} title={direction}>
      <Check checked={checked} onChange={onChange} className={factorCheck} data-testid={`check-${direction}`} />
    </div>
  );
}

export type FactorSelectorProps = {
  planFactors: PlanFactor[];
  controller: PlanController;
  departmentFactors: DepartmentFactor[];
};

export function FactorSelector({
  planFactors,
  departmentFactors,
  controller,
}: FactorSelectorProps): React.ReactElement {
  return (
    <fieldset className="mt-2 factor-selector">
      <legend className="d-flex align-items-center mb-3">
        Plan factors{' '}
        <Dialog>
          <DialogTrigger>
            <Button className="ml-auto" variant="outline-secondary" data-testid="toggle-factors">
              Factors <Icon icon="sparkle" className="ml-1" />
            </Button>
          </DialogTrigger>
          <FactorDialog factors={planFactors} controller={controller} />
        </Dialog>
      </legend>
      {planFactors.map((factor) => (
        <div className="d-flex mb-3" key={factor.id} data-testid={`plan-factor-${factor.id}`}>
          <div className="d-flex align-items-start">
            <FactorCheck direction="client" factor={factor.id} scope="plan" />
            <FactorCheck direction="provider" factor={factor.id} scope="plan" />
          </div>
          <FactorOption factor={factor} />
        </div>
      ))}
      {departmentFactors.length > 0 && (
        <>
          <legend className="d-flex align-items-center mb-3">Department factors</legend>
          {departmentFactors.map((factor) => (
            <div className="d-flex mb-3" key={factor.id} data-testid={`department-factor-${factor.id}`}>
              <div className="d-flex align-items-start">
                <FactorCheck direction="client" factor={factor} scope="department" />
                <FactorCheck direction="provider" factor={factor} scope="department" />
              </div>
              <FactorOption factor={factor} />
            </div>
          ))}
        </>
      )}
    </fieldset>
  );
}
