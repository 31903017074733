import { useRef } from 'react';
import { useForm, useOnFieldChange } from '@ff-it/form';
import { SelectInstance } from '@ff-it/ui';
import type { EmbeddedCompany } from 'types';
import { ProductField } from 'modules/supplier/products/ProductField';
import { CompanyField } from 'modules/core/company/components';
import { FieldsProps } from './types';
import { ProductActivityField } from './ProductActivityField';
import { initialState } from './const';

export function ProviderFirst({ type, kind, required, department }: FieldsProps): React.ReactElement {
  const form = useForm();
  const productFieldRef = useRef<SelectInstance<any>>(null);
  const provider = useOnFieldChange<EmbeddedCompany | null>(
    'provider',
    (value) => {
      if (value) {
        form.change('product', null);
        productFieldRef.current?.focus();
      } else {
        form.initialize(initialState);
      }
    },
    false, // clearing does not handle blur and we reset with with kind
  );

  const disabled = !provider;

  return (
    <>
      <CompanyField
        label="Provider"
        name="provider"
        menuPortalTarget={document.body}
        required={required}
        includeBranches={true}
        autoFocus
        openMenuOnFocus
        defaultOptions
      />
      <ProductField
        label="Product"
        type={type}
        kind={kind}
        provider={provider?.id}
        name="product"
        menuPortalTarget={document.body}
        defaultOptions
        required
        key={`kind-${provider?.id || 0}-${department || 0}`}
        disabled={disabled}
        openMenuOnFocus
        selectRef={productFieldRef}
        pageSize={50}
        department={department}
      />
      <ProductActivityField />
    </>
  );
}
