import { ReactElement } from 'react';
import { AttachmentField, FormInline } from 'components';
import { TextField, DateRangePickerField, IntField, SelectField } from '@ff-it/form';
import { transparencyOptions } from 'options';
import { CompanyField } from 'modules/core/company/components';
import { ClientBrandField } from '../brand/components';

const initialPerson = {
  brand: null,
  first_name: '',
  last_name: '',
};

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <CompanyField name="client" label="Client" className="col-md-3" required disabled includeBranches={false} />
        <CompanyField
          name="payer"
          label="Payer"
          className="col-md-3"
          help="Leave empty to assign client"
          includeBranches={false}
        />
        <TextField name="number" label="Number" className="col-md-3" help="Leave blank to assign" />
        <DateRangePickerField label="Period" fromName="date_from" toName="date_to" className="col-md-3" required />
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-row">
            <SelectField
              name="transparency"
              label="Transparency"
              options={transparencyOptions}
              simple
              className="col-md-6"
              required
            />
            <IntField label="Invoice payment days" name="invoice_payment_days" className="col-md-6" />
          </div>
        </div>
        <div className="col-md-6">
          <AttachmentField name="attachments" label="Attachments" />
        </div>
      </div>

      <div className="form-row">
        <TextField
          name="terms"
          className="col-6"
          label="Terms and conditions"
          multiline
          autosize
          help="General terms and conditions. Visible in campaign blocks."
        />
        <TextField
          name="notes"
          className="col-6"
          label="Notes"
          multiline
          autosize
          help="Private notes. Not visible in campaign."
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInline
            name="signature_persons"
            title="Signature persons"
            initialValues={initialPerson}
            help="Will be shown in acceptance certificate"
            testId="persons"
          >
            {(name) => (
              <div className="form-row">
                <TextField
                  name={`${name}.first_name`}
                  placeholder="First name"
                  className="col-md-4"
                  required
                  size="sm"
                />
                <TextField name={`${name}.last_name`} placeholder="Last name" className="col-md-4" required size="sm" />
                <ClientBrandField name={`${name}.brand`} placeholder="Any brand" className="col-md-4" size="sm" />
              </div>
            )}
          </FormInline>
        </div>
      </div>
    </div>
  );
}
