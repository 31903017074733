import type { ReactElement } from 'react';
import { Box, SortType } from '@ff-it/ui';
import { TableContext } from './context';
import type { FilterType, PageData, RowType, TableController, TablePageProps } from './types';
import useSWR from 'swr';
import { fetcher } from 'services';
import { RequestError, RequestFailure } from '@ff-it/api';
import equal from 'fast-deep-equal';
import { toast } from 'react-toastify';

const drfPageFetcher = async <R extends RowType>(key: any): Promise<PageData<R>> => {
  const res = await fetcher<
    | {
        results: R[];
        count: number;
        // FIXME: only reports
        total?: Record<string, string>;
      }
    | R[]
  >(key);

  // FIXME: pageSize Infinity
  if (Array.isArray(res)) {
    return {
      rows: res,
      total: res.length,
    };
  }
  const { results, count, total } = res;

  return {
    totals: total,
    rows: results,
    total: count,
  };
};

function isFilterError(error: RequestFailure<any>): boolean {
  return error.error instanceof RequestError && error.status === 400;
}

// @TODO uncouple from DRF
export function TablePage<R extends RowType>({
  columns,
  getKey,
  state,
  setState,
  defaultFilter,
  renderTable,
}: TablePageProps<R>): ReactElement {
  const { data, isLoading, error } = useSWR<PageData<R>>(getKey(state), {
    keepPreviousData: true,
    fetcher: drfPageFetcher,
    onError: (error: RequestFailure<any>) => {
      if (isFilterError(error) && !equal(state.filter, defaultFilter)) {
        toast.error('Resetting filter');
        setState((state) => ({ ...state, filter: defaultFilter, pageIndex: 1 }));
      }
    },
  });

  if (error) {
    // FIXME: what abouy other non 400 errors?
    if (!isFilterError(error)) {
      throw error.error;
    }
  }

  const setFilter = (filter: FilterType): void => setState((state) => ({ ...state, filter, pageIndex: 1 }));
  const onPageChange = (pageIndex: number, pageSize: number): void =>
    setState((state) => ({ ...state, pageIndex, pageSize }));
  const onSort = (sort?: SortType): void => setState((state) => ({ ...state, sort, pageIndex: 1 }));

  const controller: TableController<R> = {
    ...state,
    columns,
    defaultFilter,
    setFilter,
    onPageChange,
    onSort,
    data,
    isLoading,
  };

  return (
    <Box>
      <TableContext.Provider value={controller}>{renderTable(controller)}</TableContext.Provider>
    </Box>
  );
}
